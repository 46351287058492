import GSAP from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

GSAP.registerPlugin(ScrollTrigger)

export default class Hero {
  constructor(item) {
    this.item = item
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section, idx) => {
      const picture = R.G.tag("img", section)[0]

      if (!picture) return

      GSAP.to(picture, {
        duration: 3,
        scale: 1.18,
        ease: "linear",
        scrollTrigger: {
          trigger: section,
          scrub: true,
          start: "top top",
          end: "bottom top",
        },
      })

      if (idx === R.G.class(this.item).length - 1) {
        setTimeout(() => {
          ScrollTrigger.refresh()
        }, 1000)
      }
    })
  }
}
