import GSAP from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { Listeners, BM } from "utils/dom"
import { o3 } from "utils/easings"

GSAP.registerPlugin(ScrollTrigger)
GSAP.registerPlugin(ScrollToPlugin)

export default class Timeline {
  constructor(item) {
    this.item = item
    this.debounceDelay = 200
    this.resizeTimeout = null
  }

  init(item) {
    if (!this.item) this.item = item

    R.G.class(this.item).forEach((section, idx) => {
      setTimeout(() => {
        this.width = window.innerWidth

        BM(this, ["fn", "over", "out", "click", "resize"])

        Listeners(window, "a", "resize", this.resize)
        const eT = ["mouseenter", "mouseleave", "click"]

        const wrapper = R.G.class("ti-w", section)[0]
        const inner = R.G.class("ti-inner", section)[0]
        const progress = R.G.class("ti-p", wrapper)[0]
        const sections = Array.from(R.G.class("i-s", inner))
        this.wInner = inner.getBoundingClientRect().width

        wrapper.style.height = `${this.wInner}px`

        this.animation = GSAP.fromTo(
          inner,
          { x: "0px" },
          {
            x: `-${this.wInner - this.width}px`,
            ease: "none",
            scrollTrigger: {
              trigger: wrapper,
              scrub: true,
              start: "top top",
              end: "bottom bottom",
              onEnter: () => {
                inner.style.position = `fixed`
                GSAP.set(progress, { position: "fixed", autoAlpha: 0 })

                GSAP.fromTo(
                  progress,
                  {
                    autoAlpha: 0,
                  },
                  {
                    autoAlpha: 1,
                  }
                )
              },
              onEnterBack: () => {
                inner.style.position = `fixed`
                progress.style.position = `fixed`

                GSAP.set(progress, { position: "fixed", autoAlpha: 0 })

                GSAP.fromTo(
                  progress,
                  {
                    autoAlpha: 0,
                  },
                  {
                    autoAlpha: 1,
                  }
                )
              },
              onLeave: () => {
                inner.style.position = `sticky`

                GSAP.fromTo(
                  progress,
                  {
                    autoAlpha: 1,
                  },
                  {
                    autoAlpha: 0,
                  }
                )

                GSAP.set(progress, { position: "absolute", autoAlpha: 0 })
              },
              onLeaveBack: () => {
                inner.style.position = `sticky`

                GSAP.fromTo(
                  progress,
                  {
                    autoAlpha: 1,
                  },
                  {
                    autoAlpha: 0,
                  }
                )

                GSAP.set(progress, { position: "absolute", autoAlpha: 0 })
              },
            },
          }
        )

        sections.forEach((sectionItem) => {
          const bg = R.G.class("bg", sectionItem)[0]
          if (bg) {
            const img = R.G.tag("picture", sectionItem)[0]
            GSAP.fromTo(
              img,
              { x: "-5vw" },
              {
                x: "5vw",
                scrollTrigger: {
                  trigger: bg,
                  containerAnimation: this.animation,
                  start: "left right",
                  end: "right left",
                  scrub: true,
                  invalidateOnRefresh: true,
                  onRefresh: (self) => {
                    if (self.start < 0) {
                      self.animation.progress(
                        GSAP.utils.mapRange(self.start, self.end, 0, 1, 0)
                      )
                    }
                  },
                },
              }
            )
          }
        })

        const yearContainer = R.G.class("ti-p-ul", this.item)[0]
        const years = Array.from(R.G.class("c", R.G.class("ti-p-li")))
        const progressBars = Array.from(R.G.class("p", R.G.class("ti-p-li")))
        const totalWidth = inner.getBoundingClientRect().width
        let activeIndex = 0

        eT.forEach((e, i) => {
          years.forEach((y, i2) => {
            let targetY = R.G.class("y", y)[0]
            let targetL = R.G.class("l", y)[0]

            if (targetY) GSAP.set(targetY, { yPercent: 0 })
            if (targetL) GSAP.set(targetL, { yPercent: 110 })

            if (e === "click") {
              Listeners(y, "a", e, this.click)
            } else {
              Listeners(y, "a", e, this.fn)
              Listeners(y, "a", e, this.fn)
            }
          })
        })

        progressBars.forEach((progressBar) => {
          progressBar.style.width = "0"
        })

        this.sectionBounds = sections.map((section) => {
          const rect = section.getBoundingClientRect()
          return {
            start: rect.left,
            end: rect.right,
          }
        })
        this.scrollTriggers = []

        this.scrollTriggers.push(
          ScrollTrigger.create({
            trigger: inner,
            start: "left left",
            end: `+=${totalWidth}px`,
            scrub: true,
            onUpdate: (self) => {
              const scrollPos = self.progress * totalWidth
              this.sectionBounds.forEach((bounds, index) => {
                if (scrollPos >= bounds.start && scrollPos <= bounds.end) {
                  if (index <= activeIndex) {
                    const sectionWidth = bounds.end - bounds.start
                    let sectionProgress =
                      (scrollPos - bounds.start) / sectionWidth
                    progressBars[index].style.width = `${
                      sectionProgress * 100
                    }%`
                  }
                }

                if (index === activeIndex && scrollPos > bounds.end) {
                  progressBars[activeIndex].style.width = `100%`
                  activeIndex = Math.min(
                    activeIndex + 1,
                    progressBars.length - 1
                  )
                }

                if (index === activeIndex && scrollPos < bounds.start) {
                  progressBars[activeIndex].style.width = `0%`
                  activeIndex = Math.max(activeIndex - 1, 0)
                }
              })
            },
          })
        )

        this.yearContainerAnimation = GSAP.to(yearContainer, {
          x: 0,
          ease: "none",
          scrollTrigger: {
            trigger: wrapper,
            scrub: true,
            start: "top center",
            end: "bottom bottom",
            onUpdate: (self) => {
              if (
                yearContainer.getBoundingClientRect().right <= window.innerWidth
              )
                return

              const x =
                self.progress *
                (yearContainer.getBoundingClientRect().width -
                  window.innerWidth)
              GSAP.set(yearContainer, { x: -x })
            },
          },
        })
        if (idx === R.G.class(this.item).length - 1) {
          ScrollTrigger.refresh()
        }
      }, 500)
    })
  }

  fn(e) {
    this.event = e
    this.eventType = e.type
    this.eventTarget = e.currentTarget

    this.run()
  }

  run() {
    if (this.eventType === "mouseenter") {
      this.over()
    } else if (this.eventType === "mouseleave") {
      this.out()
    }
  }

  click(event) {
    const target = event.currentTarget
    const parent = target.parentElement.parentElement.parentElement
    const liElements = Array.from(R.G.class("ti-p-li", parent.parentElement))
    const years = liElements.map((li) => R.G.class("c", li)[0])
    const index = years.findIndex((year) => year === target)
    const bg = R.G.class("bg", this.item)[0]

    if (index !== -1) {
      const targetSection = R.G.class("i-s", this.item)[index]
      const rect = targetSection.getBoundingClientRect()
      const scrollPosition =
        rect.left + window.scrollY + bg.getBoundingClientRect().width

      GSAP.to(window, {
        scrollTo: { y: scrollPosition },
        duration: 1.5,
        ease: o3,
      })
    }
  }

  over() {
    const target1 = R.G.class("y", this.eventTarget)[0]
    const target2 = R.G.class("l", this.eventTarget)[0]

    if (target1 && target2) {
      GSAP.set(target2, { yPercent: 100 })

      const tl = GSAP.timeline()

      tl.to(target1, { yPercent: -110 }, 0)
      tl.to(target2, { yPercent: -0 }, 0)
    }
  }

  out() {
    const target1 = R.G.class("y", this.eventTarget)[0]
    const target2 = R.G.class("l", this.eventTarget)[0]

    if (target1 && target2) {
      const tl = GSAP.timeline()
      tl.to(target1, { yPercent: 0 }, 0)
      tl.to(target2, { yPercent: 110 }, 0)
    }
  }

  resize() {
    clearTimeout(this.resizeTimeout)

    this.resizeTimeout = setTimeout(() => {
      this.resizeItem = this.item

      const inner = R.G.class("ti-inner", this.item)[0]
      const wrapper = R.G.class("ti-w", this.item)[0]
      const progress = R.G.class("ti-p", wrapper)[0]
      const yearContainer = R.G.class("ti-p-ul", this.item)[0]
      const sections = Array.from(R.G.class("i-s", inner))

      this.wInner = inner.getBoundingClientRect().width

      wrapper.style.height = `${this.wInner}px`

      GSAP.set(inner, { x: "0px" })
      GSAP.set(progress, { position: "absolute", autoAlpha: 0 })
      GSAP.set(yearContainer, { x: 0 })

      this.sectionBounds = sections.map((section) => {
        const rect = section.getBoundingClientRect()
        return {
          start: rect.left,
          end: rect.right,
        }
      })

      if (this.animation.scrollTrigger) {
        this.animation.scrollTrigger.kill()
      }

      this.animation.kill()
      this.yearContainerAnimation.kill()
      this.scrollTriggers.forEach((st) => st.kill())

      this.width = window.innerWidth

      this.init(this.resizeItem)
    }, this.debounceDelay)
  }
}
